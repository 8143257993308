import { apiFactory } from '@/utils/constant'

const fbaSurchargeSettingsApi = {
  getLabelChargeInfo: '/systemService/labelCharget/getLabelChargeInfo', //获取对外分销后台贴标费
  setLabelCharget:'/systemService/labelCharget/setLabelCharget' //设置对外分销后台贴标费
}

for (const key in fbaSurchargeSettingsApi) {
  fbaSurchargeSettingsApi[key] = apiFactory(fbaSurchargeSettingsApi[key])
}

export default fbaSurchargeSettingsApi