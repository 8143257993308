<template>
  <div class="app-container baseUserRoleComponent app-flex-col-container" v-loading="loading">
    <div class="flex-col-content">
      <BaseForm style="width: 50%" size="small" ref="form" label-width="120PX" :cols="cols" :form="form">
        <template #labelCharget>
          <div class="flex">
            <el-input size="mini" style="width: 300px" placeholder="请输入贴标费" v-model="form['labelCharget']" clearable></el-input>￥
          </div>
        </template>
      </BaseForm>
      <div class="btn-wrapper">
        <el-button type="primary" size="small" @click="doSubmit" :loading="loading"> 提交 </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { addUserField } from './const.js'
import { commonFromMixin } from '@/mixins'
import fbaSurchargeSettingsApi from '@/api/fbaSurchargeSettingsApi'
export default {
  mixins: [commonFromMixin],
  components: {},
  props: {},
  computed: {
    cols() {
      return addUserField.call(this)
    }
  },
  data() {
    return {
      form: {},
      loading: false
    }
  },
  async created() {
    this.initGetInfo()
  },
  methods: {
    async initGetInfo() {
      const res = await awaitResolveDetailLoading(fbaSurchargeSettingsApi.getLabelChargeInfo())
      if(!res) return
      const { isCollectLabelCharget, labelCharget } = res || {}
      this.$set(this.form, 'isCollectLabelCharget', isCollectLabelCharget)
      this.$set(this.form, 'labelCharget', labelCharget)
    },
    async doSubmit() {
      const valid = await this.validate()
      if (!valid) return
      const res = await awaitResolveDetailLoading(fbaSurchargeSettingsApi.setLabelCharget({ ...this.form }))
      if (!res) return this.$message.warning('保存失败')
      this.initGetInfo()
      this.$message.success('保存成功')
    }
  }
}
</script>

<style lang="scss" scoped>
  .btn-wrapper{
    margin-left: 60px;
  }
</style>
